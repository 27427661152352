import React from 'react';
import { styled, theme } from '../../Theme';
import { media } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import RowSectionBlockModel from './Model/RowSectionBlockModel.interface';
import ContentArea from '../../Shared/ContentArea/ContentArea';
import { applyEditModeAttr } from '../../Shared/Common/Helpers';
import GridItem from '../../Shared/Grid/GridItem';
import Grid from '../../Shared/Grid/Grid';

type PropType = {
  content: RowSectionBlockModel;
};

function RowSectionBlock({
  content: {
    backgroundColor,
    heading,
    shortText,
    blocks,
    inEditMode,
    extraMargin,
  },
}: PropType) {
  const rowSectionStyle: StyleOrStyleArray = {
    backgroundColor: backgroundColor
      ? theme.mapEpiColorConstants(backgroundColor)
      : theme.none,
  };

  return (
    <GridItem layout="FullWidth" extraMargin={extraMargin}>
      <styled.Div css={rowSectionStyle}>
        <InnerWrapper>
          <Content>
            {heading && (
              <Heading {...applyEditModeAttr(inEditMode && 'Heading')}>
                {heading}
              </Heading>
            )}
            <styled.P {...applyEditModeAttr(inEditMode && 'ShortText')}>
              {shortText}
            </styled.P>
          </Content>
          <Grid type={12} data-content-area>
            {Array.isArray(blocks) && <ContentArea childItems={blocks} />}
          </Grid>
        </InnerWrapper>
      </styled.Div>
    </GridItem>
  );
}

export default RowSectionBlock;

const InnerWrapper = styled.div({
  maxWidth: theme.contentMaxWidth,
  margin: { x: 'auto' },
  padding: { y: theme.spacing(10) },
  marginBottom: 0,
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { y: theme.spacing(16), x: 0 },
  }),
});

const Heading = styled.h2({
  font: {
    size: theme.iota,
    weight: theme.fontWeight.bold,
  },
  marginBottom: theme.spacing(4),
});

const Content = styled.div({
  maxWidth: theme.rowSectionContentMaxWidth,
  margin: { x: 'auto', bottom: theme.spacing(4) },
  padding: { x: theme.spacing(4) },
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { x: 0 },
    marginBottom: theme.spacing(16),
  }),
});
